import React, {useEffect, useState} from 'react';
import OnboardingStepper from "../Onboarding/OnboardingStepper";
import CreateChallengeName from './CreateChallengeName';
import CreateChallengeNonProfitSearch from './CreateChallengeNonProfitSearch';
import CreateChallengePictures from './CreateChallengePictures';
import {connect} from "react-redux";
import {createChallenge} from '../../api/challenges';
import {withRouter} from "react-router-dom";
import CreateChallengeGoals from './CreateChallengeGoals';
import CreateChallengeEndDate from './CreateChallengeEndDate';
import HorizontalStepper from "../../components/atoms/HorizontalStepper";
import SvgTarget from "../../components/icons/SvgTarget";
import SvgCalendar from "../../components/icons/SvgCalendar";
import { EditorState} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import SvgSearch from "../../components/icons/SvgSearch";
import SvgTypeStyles from "../../components/icons/SvgTypeStyles";
import SvgAddPhoto from "../../components/icons/SvgAddPhoto";


function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
    }
}


function CreateChallengeMain(props) {
    const [step, setStep] = useState(1);
    const [switchingStep, setSwitchingStep] = useState(false);
    const [renderedStep, setRenderedStep] = useState(1);
    const [name, setName] = useState('');
    const [description, setDescription] = useState(EditorState.createEmpty());
    const [endDate, setEndDate] = useState(new Date());
    const [ein, setEin] = useState('');
    const [images, setImages] = useState([]);
    const [coverPic, setCoverPic] = useState('');
    const [donationsGoal, setDonationsGoal] = useState('');
    const [awarenessGoal, setAwarenessGoal] = useState('');
    const [pledgeGoal, setPledgeGoal] = useState('');
    const [pledgeText, setPledgeText] = useState("");
    const [npName, setNpName] = useState('')
    const [selectedGoalType, setSelectedGoalType] = useState(null);
    const [selectedNp, setSelectedNp] = useState(null)

    const NUM_STEPS = 5;

    const submit = async () => {
        const contentState = description.getCurrentContent();
        const htmlContent = stateToHTML(contentState);
        const challenge = {
            non_profit_id: ein,
            challenge_name: name,
            description: htmlContent,
            end_date: endDate,
            cover_image: coverPic,
            donations_goal: donationsGoal,
            awareness_goal: awarenessGoal,
            pledge_goal: pledgeGoal,
            pledge: pledgeText,
            images: images
        }
        await createChallenge(props.authUser, challenge);
        props.history.push('/challenges');
    };

    useEffect(() => {
        if (!switchingStep) {
            setRenderedStep(step);
        }
    }, [switchingStep, step]);

    const changeStep = (newStep) => {
        setSwitchingStep(true);
        setTimeout(() => {
            setStep(newStep);
            setSwitchingStep(false);
        }, 300);
    }

    const goNextStep = () => {
        if (step === NUM_STEPS) {
            submit();
        }
        changeStep(step + 1);


    }

    const goBackStep = () => {
        changeStep(step - 1);
    }

    const handleSwitchGoalType = (type) => {
        setSelectedGoalType(type);
        if (type === 'donation') {
            setAwarenessGoal('')
            setPledgeGoal('')
            setPledgeText('')
        }
        if (type === 'awareness') {
            setDonationsGoal('')
            setPledgeGoal('')
            setPledgeText('')
        }
        if (type === 'pledge') {
            setDonationsGoal('')
            setAwarenessGoal('')
        }
    };


    const stepStyle = {
        opacity: switchingStep ? 0 : 1,
        transform: switchingStep ? 'translateY(20px)' : 'translateY(0)',
        transition: 'opacity 300ms, transform 300ms'
    };

    const renderStep = () => {
        switch (renderedStep) {
            case 1:
                return <CreateChallengeNonProfitSearch
                    goNextStep={goNextStep}
                    ein={ein}
                    setEin={setEin}
                    setNpName={setNpName}
                    setSelectedNp={setSelectedNp}
                />;
            case 2:
                return <CreateChallengeGoals
                    awarenessGoal={awarenessGoal}
                    donationsGoal={donationsGoal}
                    pledgeGoal={pledgeGoal}
                    setAwarenessGoal={setAwarenessGoal}
                    setDonationsGoal={setDonationsGoal}
                    setPledgeGoal={setPledgeGoal}
                    pledgeText={pledgeText}
                    setPledgeText={setPledgeText}
                    npName={npName}
                    selectedNp={selectedNp}
                    selectedGoalType={selectedGoalType}
                    handleSwitchGoalType={handleSwitchGoalType}
                    goNextStep={goNextStep}
                    goBackStep={goBackStep}
                />;
            case 3:
                return <CreateChallengeName
                    name={name}
                    setName={setName}
                    npName={npName}
                    description={description}
                    setDescription={setDescription}
                    selectedGoalType={selectedGoalType}
                    goNextStep={goNextStep}
                    goBackStep={goBackStep}
                />;
            case 4:
                return <CreateChallengeEndDate
                    endDate={endDate}
                    setEndDate={setEndDate}
                    goNextStep={goNextStep}
                    goBackStep={goBackStep}
                />;
            case 5:
                return <CreateChallengePictures
                    name={name}
                    description={description}
                    goNextStep={goNextStep}
                    goBackStep={goBackStep}
                    coverPic={coverPic}
                    selectedNp={selectedNp}
                    setCoverPic={setCoverPic}
                    donationsGoal={donationsGoal}
                    awarenessGoal={awarenessGoal}
                    pledgeGoal={pledgeGoal}
                    pledgeText={pledgeText}
                    endDate={endDate}
                />;
            default:
                return null;
        }
    };

    const steps = [
        {label: 'Select a cause', Icon: SvgSearch},
        {label: 'Choose a goal', Icon: SvgTarget},
        {label: 'Add details', Icon: SvgTypeStyles},
        {label: 'Set end date', Icon: SvgCalendar},
        {label: 'Add picture', Icon: SvgAddPhoto},
    ];

    return (
        <div className='flex bg-white flex-col items-center min-h-[100vh] p-5 gap-5'>
            <HorizontalStepper steps={steps} currentStep={step} />
            <div className='flex flex-col items-center flex-1 overflow-y-scroll pt-40' style={stepStyle}>
                {renderStep()}
            </div>
        </div>
    );
}

export default withRouter(connect(mapStateToProps)(CreateChallengeMain));
