import React, {useEffect, useState} from 'react';
import OnboardingName from "./OnboardingName";
import OnboardingDob from "./OnboardingDob";
import OnboardingBio from "./OnboardingBio";
import OnboardingPictures from "./OnboardingPictures";
import OnboardingCity from "./OnboardingCity";
import OnboardingCategories from "./OnboardingCategories";
import OnboardingFavoriteCauses from "./OnboardingFavoriteCauses";
import {connect} from "react-redux";
import {editUser, getUser} from "../../api/users";
import {acceptInvite} from '../../api/invites';
import HorizontalStepper from "../../components/atoms/HorizontalStepper";
import SvgCalendar from "../../components/icons/SvgCalendar";
import SvgEditNote from "../../components/icons/SvgEditNote";
import SvgPin from "../../components/icons/SvgPin";
import SvgPerson from "../../components/icons/SvgPerson";
import SvgCake from "../../components/icons/SvgCake";
import SvgFavorite from "../../components/icons/SvgFavorite";
import SvgVerified from "../../components/icons/SvgVerified";
import OnboardingPhone from "./OnboardingPhone";
import SvgPhone from "../../components/icons/SvgPhone";
import { getNonProfitPublic } from '../../api/nonProfits';


function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        authUser: state.userReducer.authUser,
        path: state.userReducer.path,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setUser: (user) => {
            dispatch({
                type: "SET_USER",
                user,
            })
        },
        setPath: (path) => {
            dispatch({
                type: "SET_PATH",
                path,
            })
        },
        setReferralInfo: (referralInfo) => {
            dispatch({
                type: "SET_REFERRAL_INFO",
                referralInfo,
            })
        },
        addInterest: (interest) => {
            dispatch({
                type: "ADD_INTEREST",
                interest,
            })
        },
    }
}

function OnboardingMain(props) {
    const [step, setStep] = useState(1);
    const [switchingStep, setSwitchingStep] = useState(false);
    const [renderedStep, setRenderedStep] = useState(1);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [tagline, setTagline] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('')
    const [dob, setDob] = useState(new Date());
    const [bio, setBio] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [categories, setCategories] = useState([]);
    const [profilePic, setProfilePic] = useState('');
    const [coverPic, setCoverPic] = useState('');
    const [selectedNp, setSelectedNp] = useState({});
    const [canSubmit, setCanSubmit] = useState(false)
    const [addWorkModalOpen, setWorkModalOpen] = useState(false);
    const [ein, setEin] = useState("");
    const [np, setNp] = useState({});

    const NUM_STEPS = 8;

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search);
        const path = queryParameters.get('path', "");
        if (!!path) props.setPath(path);

        const ein = queryParameters.get("ein")

        if (!!ein) {
            setEin(ein)
        }
    }, [])

    useEffect(() => {
        if (!!ein) getNpInfo(ein);
        else if (!!props.path && !props.path.includes("non-profit")) {
            const inviteId = window.atob(props.path.split("/")[1]);
            const ein = inviteId.split("-")
            getNpInfo(`${ein[1]}-${ein[2]}`);
        }

        getNpInfo();
    }, [ein, props.path])


    const getNpInfo = async (ein) => {
        if (!ein) return;
        const res = await getNonProfitPublic(ein);
        setNp(res.non_profit);
    }


    useEffect(() => {
        if (!switchingStep) {
            setRenderedStep(step);
        }
    }, [switchingStep, step]);

    const changeStep = (newStep) => {
        setSwitchingStep(true);
        setTimeout(() => {
            setStep(newStep);
            setSwitchingStep(false);
        }, 300);
    }

    const goNextStep = () => {
        if (step === NUM_STEPS) {
            submit();
        }
        changeStep(step + 1);


    }

    const goBackStep = () => {
        changeStep(step - 1);
    }

    const onChangeCategory = (category) => {
        let newCategories = [...categories];
        if (categories.includes(category)) {
            newCategories = newCategories.filter(currentCategory => currentCategory !== category)
        } else {
            newCategories.push(category);
        }
        setCategories(newCategories);
    }

    const submit = async () => {
        const nux = props.user.nux;
        if (bio.length > 0) nux.add_bio = true;
        if (!!profilePic) nux.add_picture = true;
        const paramsToEdit = {
            first_name: firstName,
            last_name: lastName,
            tagline: tagline,
            bio: bio,
            city: city,
            state: state,
            birthday: dob,
            categories: categories,
            onboarded: true,
            nux: nux,
            phone: phoneNumber.replace("-", ""),
        }
        await editUser(props.authUser, paramsToEdit);
        const user = await getUser(props.authUser);

        if (props.path.includes("referral")) {
            const inviteId = window.atob(props.path.split("/")[1]);
            const res = await acceptInvite(props.authUser, inviteId);
            props.setReferralInfo(res);
        }

        if (props.user.username === "onboardingflow") user.user.onboarded = true
        props.setUser(user.user);

        if (props.path.includes("non-profit")) {
            console.log("Redirectinggg")
            props.history.push(props.path);
        }

    }

    const stepStyle = {
        opacity: switchingStep ? 0 : 1,
        transform: switchingStep ? 'translateY(20px)' : 'translateY(0)',
        transition: 'opacity 300ms, transform 300ms'
    };

    const followNp = (nonProfit) => {
        props.addInterest({id: nonProfit.ein, name: nonProfit.name})
        setCanSubmit(true);
    }

    const renderStep = () => {
        switch (renderedStep) {
            case 1:
                return <OnboardingName
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    tagline={tagline}
                    setTagline={setTagline}
                    goNextStep={goNextStep}
                    np={np}
                />;
            case 2:
                return <OnboardingDob
                    dob={dob}
                    setDob={setDob}
                    goNextStep={goNextStep}
                    goBackStep={goBackStep}
                />;
            case 3:
                return <OnboardingPhone
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    goNextStep={goNextStep}
                    goBackStep={goBackStep}
                />;
            case 4:
                return <OnboardingBio
                    bio={bio}
                    setBio={setBio}
                    firstName={firstName}
                    lastName={lastName}
                    goNextStep={goNextStep}
                    goBackStep={goBackStep}
                />;
            case 5:
                return <OnboardingPictures
                    bio={bio}
                    firstName={firstName}
                    lastName={lastName}
                    goNextStep={goNextStep}
                    goBackStep={goBackStep}
                    profilePic={profilePic}
                    coverPic={coverPic}
                    setProfilePic={setProfilePic}
                    setCoverPic={setCoverPic}
                    user={props.user}
                />;
            case 6:
                return <OnboardingCity
                    city={city}
                    state={state}
                    setCity={setCity}
                    setState={setState}
                    goNextStep={goNextStep}
                    goBackStep={goBackStep}
                />;
            case 7:
                return <OnboardingCategories
                    categories={categories}
                    onChangeCategory={onChangeCategory}
                    goNextStep={goNextStep}
                    goBackStep={goBackStep}
                />;
            case 8:
                return <OnboardingFavoriteCauses
                    goNextStep={goNextStep}
                    goBackStep={goBackStep}
                    categories={categories}
                    city={city}
                    state={state}
                    followNp={followNp}
                    canSubmit={canSubmit}
                />;
            default:
                return null;
        }
    };
    const steps = [
        {label: 'Your Name', Icon: SvgPerson},
        {label: 'Your Age', Icon: SvgCake},
        {label: 'Your Phone', Icon: SvgPhone},
        {label: 'Add a Bio', Icon: SvgEditNote},
        {label: 'Pictures', Icon: SvgCalendar},
        {label: 'Location', Icon: SvgPin},
        {label: 'Preferences', Icon: SvgFavorite},
        {label: 'Experience', Icon: SvgVerified},
    ];

    return (
        <div className='flex bg-white flex-col items-center h-[100vh] p-5 gap-5'>
            <HorizontalStepper steps={steps} currentStep={step}/>
            <div className='flex flex-col items-center justify-center flex-1' style={stepStyle}>
                {renderStep()}
            </div>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingMain);
