import * as React from "react"

const SvgLock = (props) => (
    <svg width="381" viewBox="0 0 381 508" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className}>
        <g clip-path="url(#clip0_2216_134)">
            <path d="M320.676 239.067C306.169 239.298 295.354 223.896 300.709 210.319C309.885 185.526 328.959 112.869 307.235 75.1379C277.908 31.5889 218.253 58.0864 192.269 101.65C170.569 132.464 165.969 158.533 160.186 180.525C157.191 191.907 145.527 198.702 134.158 195.713C122.776 192.721 115.975 181.068 118.968 169.685C125.433 145.098 128.836 115.011 154.954 77.9233C197.387 9.0077 300.809 -17.2987 344.217 53.9499C359.805 81.088 363.789 118.525 356.056 165.223C346.142 209.353 344.315 238.305 320.676 239.067Z" fill="#3B82F6" />
            <path d="M320.677 246.072C317.305 246.072 313.983 245.472 310.803 244.288C303.715 241.65 298.078 236.409 294.932 229.531C291.786 222.653 291.506 214.962 294.145 207.874C302.408 185.548 321.443 113.854 301.166 78.6329C294.677 67.3623 284.463 60.8678 269.027 58.1945C233.314 52.0033 207.656 63.0717 185.825 94.071C164.849 123.855 154.295 163.995 148.625 185.559C144.651 200.658 129.131 209.707 114.04 205.742C98.9406 201.772 89.8859 186.257 93.8553 171.158C100.435 146.137 112.68 99.5817 139.522 61.4653C173.675 12.9661 221.792 -7.4603 278.694 2.39508C310.649 7.93079 335.407 24.5519 350.29 50.461C366.707 79.0363 370.973 118.033 362.97 166.368C357.386 200.08 347.63 226.521 347.218 227.629C343.11 238.66 332.445 246.072 320.677 246.072ZM251.1 42.5442C257.572 42.5442 264.329 43.1598 271.417 44.3878C290.998 47.7797 304.701 56.6943 313.307 71.642C323.509 89.363 326.607 115.2 322.513 148.436C319.358 174.05 312.335 199.09 307.279 212.75C305.942 216.343 306.083 220.228 307.672 223.702C309.261 227.176 312.109 229.824 315.69 231.156C317.301 231.756 318.98 232.06 320.676 232.06C326.62 232.06 332.009 228.315 334.084 222.741C334.477 221.686 343.78 196.473 349.144 164.078C356.606 119.017 352.904 83.1387 338.14 57.4399C325.399 35.262 304.015 21.0019 276.3 16.2008C225.133 7.33328 181.79 25.775 150.978 69.5322C125.587 105.585 113.76 150.554 107.407 174.713C105.4 182.346 109.974 190.184 117.602 192.189C125.224 194.195 133.068 189.621 135.073 181.993C140.967 159.577 151.939 117.85 174.367 86.0022C186.882 68.2311 200.759 56.2419 216.791 49.349C227.363 44.8041 238.704 42.5442 251.1 42.5442Z" fill="#231F20" />
            <path d="M323.05 217.618C323.05 217.618 364.725 234.958 372.622 250.752C380.52 266.547 347.122 444.812 300.562 477.792C252.15 512.085 159.895 505.998 100.384 475.007C72.5271 460.502 50.2672 438.976 32.9204 425.352C-27.1507 378.17 303.307 226.503 323.05 217.618Z" fill="#231F20" />
            <path d="M212.011 508C209.501 508 206.974 507.962 204.422 507.884C166.581 506.736 127.481 497.019 97.146 481.221C75.4085 469.902 56.8878 454.369 42.0061 441.89C37.239 437.892 32.7372 434.116 28.5917 430.86C21.1223 424.994 17.7445 417.229 18.8214 408.404C22.1442 381.18 70.6124 341.904 166.995 288.328C243.289 245.919 319.414 211.57 320.175 211.229C321.939 210.435 323.954 210.406 325.741 211.149C330.253 213.026 370.079 229.999 378.889 247.618C385.258 260.356 373.896 320.764 363.794 359.59C356.444 387.838 347.864 413.381 338.982 433.458C327.991 458.299 316.427 475.138 304.611 483.508C282.18 499.399 249.512 508 212.011 508ZM323.138 225.275C308.783 231.834 241.372 263.019 173.804 300.577C54.6028 366.835 34.2525 397.638 32.7312 410.103C32.4109 412.733 32.6331 416.217 37.2491 419.842C41.5697 423.237 46.1556 427.082 51.0118 431.154C66.1186 443.822 83.2402 458.182 103.619 468.794C132.147 483.649 169.043 492.792 204.848 493.878C242.515 495.012 275.051 487.277 296.514 472.075C314.637 459.237 334.052 417.587 349.779 357.805C364.453 302.021 368.378 259.969 366.303 253.777C361.568 244.573 338.456 231.98 323.138 225.275Z" fill="#231F20" />
            <path d="M83.3984 168.978C101.267 160.937 321.628 206.202 329.525 221.997C337.421 237.791 304.024 416.057 257.464 449.037C186.391 499.381 24.6024 461.87 10.2382 386.848C-4.12601 311.826 63.6556 177.862 83.3984 168.978Z" fill="#3B82F6" />
            <path d="M171.191 479.015C138.817 479.015 105.664 472.332 78.6694 461.465C35.855 444.233 9.10823 418.201 3.35834 388.165C-5.86851 339.976 7.4278 277.077 13.574 252.271C18.8925 230.799 25.4852 210.42 32.1368 194.885C43.3373 168.721 50.7717 165.377 53.2147 164.277C55.876 163.079 61.4137 160.587 106.103 166.119C131.77 169.297 164.335 174.404 197.803 180.499C232.266 186.776 264.187 193.484 287.69 199.387C331.564 210.406 334.109 215.495 335.793 218.862C336.896 221.067 339.824 226.924 335.083 260.476C332.079 281.745 326.971 306.732 320.698 330.834C310.93 368.376 290.252 434.397 261.516 454.752C237.375 471.852 204.7 479.014 171.191 479.015ZM58.8756 177.099C54.0555 179.608 39.5061 205.761 27.1195 255.864C14.6469 306.314 11.0018 353.576 17.121 385.53C21.8751 410.358 46.2158 433.297 83.9028 448.466C111.643 459.632 143.782 465.502 174.372 464.997C206.818 464.462 234.152 456.966 253.418 443.319C271.541 430.481 290.957 388.832 306.683 329.05C320.934 274.875 325.047 233.651 323.371 225.645C318.179 222.161 286.926 210.854 192.739 193.821C119.627 180.601 66.478 174.865 58.8756 177.099Z" fill="#231F20" />
            <path d="M128.799 287.522C126.258 298.512 130.936 307.128 138.75 312.569C134.738 326.08 124.318 365.259 140.565 365.912C156.812 366.566 165.26 336.337 168.951 319.118C181.773 318.337 188.973 309.842 191.563 297.997C199.516 261.641 137.867 248.311 128.799 287.522Z" fill="#231F20" />
            <path d="M141.125 372.93C140.845 372.93 140.564 372.925 140.283 372.913C135.526 372.722 131.57 370.605 128.842 366.793C122.852 358.419 123.49 341.036 130.732 315.091C122.57 307.497 119.389 297.119 121.972 285.944C127.566 261.759 150.03 253.5 169.327 257.842C187.415 261.912 203.211 277.526 198.406 299.496C195.341 313.519 186.837 322.702 174.723 325.374C167.25 356.943 155.96 372.93 141.125 372.93ZM135.625 289.101C133.524 298.181 138.342 303.748 142.753 306.82C145.233 308.546 146.325 311.668 145.465 314.564C135.751 347.278 139.078 357.957 140.422 358.848C140.446 358.86 140.582 358.901 140.846 358.912C140.91 358.914 140.976 358.916 141.041 358.916C146.985 358.916 155.574 348.103 162.1 317.651C162.759 314.577 165.386 312.318 168.525 312.126C177.118 311.603 182.566 306.346 184.719 296.502C187.719 282.786 177.607 274.067 166.252 271.513C148.817 267.593 138.303 277.519 135.625 289.101Z" fill="#231F20" />
        </g>
        <defs>
            <clipPath id="clip0_2216_134">
                <rect width="381" height="508" fill="white" />
            </clipPath>
        </defs>
    </svg>

)

export default SvgLock