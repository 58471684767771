import React, {useEffect, useState} from 'react';
import {Editor, EditorState, RichUtils} from 'draft-js';
import {stateFromHTML} from 'draft-js-import-html';
import 'draft-js/dist/Draft.css';
import {useRef} from 'react';
import {CompositeDecorator} from 'draft-js';
// import PlainTextInputModal from '../../modals/PlainTextInputModal';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faListUl, faListOl, faBold, faItalic, faUnderline, faCode, faLink} from "@fortawesome/free-solid-svg-icons";

// Block and Inline Style Button Components
const StyleButton = ({onToggle, style, label, active, icon}) => {
    const className = `RichEditor-styleButton ${active ? 'RichEditor-activeButton' : ''}`;
    return (
        <span className={className} onMouseDown={(e) => {
            e.preventDefault();
            onToggle(style);
        }}>
            {icon && <FontAwesomeIcon icon={icon}/>}
            {/*{label}*/}
        </span>
    );
};


// Block Style Controls
const BLOCK_TYPES = [
    {label: 'List', style: 'unordered-list-item', icon: faListUl},
    {label: 'Ordered List', style: 'ordered-list-item', icon: faListOl},
];


const BlockStyleControls = ({editorState, onToggle}) => {
    const selection = editorState.getSelection();
    const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

    return (
        <div className="RichEditor-controls">
            {BLOCK_TYPES.map((type) => (
                <StyleButton
                    key={type.label}
                    active={type.style === blockType}
                    label={type.label}
                    icon={type.icon}
                    onToggle={onToggle}
                    style={type.style}
                />
            ))}
        </div>
    );
};

// Inline Style Controls
const INLINE_STYLES = [
    {label: 'Bold', style: 'BOLD', icon: faBold},
    {label: 'Italic', style: 'ITALIC', icon: faItalic},
    {label: 'Underline', style: 'UNDERLINE', icon: faUnderline},
    {label: 'Monospace', style: 'CODE', icon: faCode},
];

const InlineStyleControls = ({editorState, onToggle}) => {
    const currentStyle = editorState.getCurrentInlineStyle();
    return (
        <div className="RichEditor-controls">
            {INLINE_STYLES.map((type) =>
                <StyleButton
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    icon={type.icon}
                    onToggle={onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};


// const FreeTextModal = ({linkModalOpen, linkText, setLinkModalOpen, setLinkText, saveLink}) => {
//     return (
//         <PlainTextInputModal
//             isOpen={linkModalOpen}
//             title={'Add URL'}
//             placeholder={'i.e. https://www.google.com'}
//             value={linkText}
//             handleInputChange={setLinkText}
//             closeModal={() => setLinkModalOpen(false)}
//             save={saveLink}
//             disabled={!linkText}
//         />
//     )
// }

// RichTextEditor Component
const RichTextEditor = ({editorState, setEditorState, initialHtml}) => {

    const editorRef = useRef(null);
    const [linkText, setLinkText] = useState("");
    const [linkModalOpen, setLinkModalOpen] = useState(false);

    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    const onTab = (e) => {
        const maxDepth = 4;
        setEditorState(RichUtils.onTab(e, editorState, maxDepth));
    };

    const toggleBlockType = (blockType) => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    };

    const toggleInlineStyle = (inlineStyle) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    };

    const focusEditor = () => {
        if (editorRef.current) {
            editorRef.current.focus();
        }
    };


    const findLinkEntities = (contentBlock, callback, contentState) => {
        contentBlock.findEntityRanges(
            (character) => {
                const entityKey = character.getEntity();
                return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
            },
            callback
        );
    };

    const Link = (props) => {
        const {url} = props.contentState.getEntity(props.entityKey).getData();
        return (
            <a href={url} style={{color: 'blue', textDecoration: 'underline'}} target="_blank"
               rel="noopener noreferrer">
                {props.children}
            </a>
        );
    };

    // Usage of the decorator
    const decorator = new CompositeDecorator([
        {
            strategy: findLinkEntities,
            component: Link,
        },
    ]);

    useEffect(() => {
        if (initialHtml && typeof initialHtml === 'string') {
            const contentState = stateFromHTML(initialHtml);
            const newEditorState = EditorState.createWithContent(contentState, decorator);
            setEditorState(newEditorState);
        } else if (!editorState) {
            // Initialize with empty editor state if initialHtml is undefined or not a string
            setEditorState(EditorState.createEmpty(decorator));
        }
    }, [initialHtml]);

    const LinkControls = () => (
        <div className="RichEditor-controls">
        <span className="RichEditor-styleButton" onMouseDown={() => setLinkModalOpen(true)}>
            <FontAwesomeIcon icon={faLink} className="mr-2"/>
            Hyperlink
        </span>
        </div>
    );

    const addLink = (url) => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithLink = contentState.createEntity('LINK', 'MUTABLE', {url});
        const entityKey = contentStateWithLink.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, {currentContent: contentStateWithLink});

        setEditorState(RichUtils.toggleLink(
            newEditorState,
            newEditorState.getSelection(),
            entityKey
        ));
    };

    const saveLink = (link) => {
        addLink(link);
        setLinkModalOpen(false);
    }

    return (
        <>
            <div className="RichEditor-root">
                <style>
                    {`
    .RichEditor-root {
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 8px;
        font-size: 14px;
    }

    .RichEditor-editor {
        border-top: 1px solid #ddd;
        padding-top: 20px;
        cursor: text;
        font-size: 16px;
        min-height: 20vh;
        max-height: 20vh;
        overflow-y: auto;
        padding: 16px;
        overflow-y: auto;
    }

    .RichEditor-controls {
        display: flex;
        font-size: 14px;
        padding: 4px;
        user-select: none;
        align-items: center;
        justify-content: flex-start;
        overflow-x: auto; /* Allow horizontal scrolling */
        gap: 4px; /* Reduce the gap between buttons */
    }

    .RichEditor-controls::-webkit-scrollbar {
        height: 6px; /* Add a scrollbar for horizontal overflow */
    }

    .RichEditor-controls::-webkit-scrollbar-thumb {
        background: #ccc; /* Scrollbar thumb color */
        border-radius: 3px; /* Slightly rounded scrollbar */
    }

    .RichEditor-styleButton {
        color: #999;
        cursor: pointer;
        margin-right: 4px; /* Reduce margin for tighter spacing */
        padding: 4px 10px; /* Adjust padding to make buttons more compact */
        border: 1px solid #ddd; /* Subtle border for buttons */
        border-radius: 4px; /* Make buttons less rounded */
        font-weight: 600;
        white-space: nowrap;
        flex-shrink: 0; /* Prevent buttons from shrinking */
    }
    
    .RichEditor-styleButton:hover {
        border: 1px solid #00000040;
        color: #000;
    }

    .RichEditor-activeButton {
        color: #3043A7;
        border: 1px solid #3043A7;
    }
    `}
                </style>

                <div className="RichEditor-controls">
                    <InlineStyleControls editorState={editorState} onToggle={toggleInlineStyle}/>
                    <BlockStyleControls editorState={editorState} onToggle={toggleBlockType}/>
                    {/* <LinkControls/> */}
                </div>
                <div className="RichEditor-editor relative z-0" onClick={focusEditor}>
                    <Editor
                        ref={editorRef}
                        editorState={editorState}
                        handleKeyCommand={handleKeyCommand}
                        onChange={setEditorState}
                        onTab={onTab}
                        placeholder="Enter your text..."
                        spellCheck={true}
                    />
                </div>
            </div>
            {/* <FreeTextModal
                saveLink={saveLink}
                setLinkModalOpen={setLinkModalOpen}
                linkModalOpen={linkModalOpen}
                linkText={linkText}
                setLinkText={setLinkText}
            /> */}
        </>
    );
};

export default RichTextEditor;

