import * as React from "react"

const SvgAbstract = (props) => (
    <svg width="359" height="368" viewBox="0 0 359 368" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className}>
        <path d="M340.707 193.336C350.067 264.52 230.243 313.059 171.379 320.799C112.515 328.539 57.2086 277.107 47.8488 205.922C38.489 134.738 -6.31626 42.2863 52.5477 34.5465C111.412 26.8067 331.347 122.152 340.707 193.336Z" fill="#054A91" />
        <path d="M326.267 225.421C330.817 260.031 303.948 289.488 267.846 311.445C231.861 333.33 187.359 347.333 158.108 351.179C100.279 358.783 45.484 308.263 36.1994 237.65C33.848 219.767 29.2722 200.572 24.6771 181.79C24.362 180.502 24.0468 179.216 23.7322 177.932C19.4507 160.463 15.2834 143.46 12.9605 128.182C10.4608 111.741 10.1443 97.5589 13.9436 87.0684C15.83 81.8596 18.7222 77.5873 22.8575 74.3802C26.9981 71.169 32.4739 68.957 39.6317 68.0158C54.0035 66.1261 78.5229 70.5306 107.577 79.4846C136.552 88.4137 169.797 101.79 201.504 117.63C233.218 133.474 263.337 151.756 286.087 170.479C297.462 179.84 306.96 189.283 313.894 198.557C320.833 207.84 325.143 216.872 326.267 225.421Z" stroke="#EFA143" stroke-width="3" />
    </svg>
)

export default SvgAbstract
