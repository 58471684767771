import React, { PureComponent } from "react";
import SvgVerification2 from "../../img/graphics/hands";

class SecuritySection6 extends PureComponent {
    render() {
        const header = "Our Commitment";
        const para1 = "We understand that trust is earned. For that reason, Starfish builds our global platform on a foundation of transparency, security, and accountability. Every feature and partnership has been carefully chosen to protect our donors and non-profit partners while making giving as simple and secure as possible."
        const para2 = "Have questions about our security measures? Contact our support team, and we'll be happy to help."
        const para3 = "support@starfishapp.com"
        return (
            <div className='flex flex-col justify-center items-center gap-y-5 min-h-[100vh] md:min-h-[80vh] max-w-[350px] sm:max-w-xl md:max-w-3xl xl:max-w-5xl self-center'>
                <div className="flex flex-row gap-x-2 items-center md:gap-x-10">
                    <span className="text-center text-3xl md:text-5xl xl:text-7xl text-starfish-black-900 font-bold">{header}</span>
                    <SvgVerification2 />
                </div>
                <span className="text-center text-xl xl:text-2xl text-starfish-black-600">{para1}</span>
                <span className="text-center text-xl xl:text-2xl text-starfish-black-800 font-bold max-w-[300px] sm:max-w-lg md:max-w-xl xl:max-w-2xl">{para2}</span>
                <span className="text-center text-xl xl:text-2xl text-starfish-yellow font-bold max-w-[300px] sm:max-w-lg md:max-w-xl xl:max-w-2xl">{para3}</span>
            </div>
        )
    }
}

export default SecuritySection6
