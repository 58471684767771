import React from "react";
import DOMPurify from "dompurify";

const RenderHtml = ({ htmlString }) => {
  // Sanitize the HTML string to prevent XSS attacks
  const sanitizedHtml = DOMPurify.sanitize(htmlString);

  // Custom component to style lists and paragraphs
  const CustomHtmlRenderer = () => {
    // Parse the sanitized HTML string into DOM nodes
    const parser = new DOMParser();
    const doc = parser.parseFromString(sanitizedHtml, "text/html");

    // Style <ul> and <ol> elements
    const ulElements = doc.querySelectorAll("ul");
    ulElements.forEach((ul) => {
      ul.style.listStyleType = "disc";
      ul.style.paddingLeft = "1.5rem";
      ul.style.marginBottom = "1rem"; // Add space after the list
    });

    const olElements = doc.querySelectorAll("ol");
    olElements.forEach((ol) => {
      ol.style.listStyleType = "decimal";
      ol.style.paddingLeft = "1.5rem";
      ol.style.marginBottom = "1rem"; // Add space after the list
    });

    // Style <p> elements
    const pElements = doc.querySelectorAll("p");
    pElements.forEach((p) => {
      p.style.marginBottom = "0.25rem"; // Add space after paragraphs
    });

    // Style <a> elements (hyperlinks)
    const aElements = doc.querySelectorAll("a");
    aElements.forEach((a) => {
      a.style.color = "#007BFF"; // Set hyperlink color
      a.style.textDecoration = "underline"; // Add underline to hyperlinks
      a.style.cursor = "pointer"; // Make the cursor a pointer on hover
    });

    // Return the styled HTML as a string
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: doc.body.innerHTML,
        }}
      />
    );
  };

  return <CustomHtmlRenderer />;
};

export default RenderHtml;
