import React, { PureComponent } from "react";
import SvgLock from "../../img/graphics/lock";
import SvgBullets2 from "../../img/graphics/bullets-double";
import BGGraphic10 from "../../img/graphics/BGGraphic10";
import BGGraphic3 from "../../img/graphics/BGGraphic3";

class SecuritySection2 extends PureComponent {
    render() {
        const smallText = "PROTECTIONS";
        const header = "Protected Donations";
        const para1 = "To provide additional security and peace of mind:"
        const bullet1 = "All donations are held in a secure account for a verification period"
        const bullet2 = "This holding period allows us to:"
        return (
            <div className="bg-blue-50 relative min-h-[100vh] md:min-h-[80vh] items-center justify-center flex flex-col w-screen pb-32 md:pb-0">
                <div className='flex flex-col pt-10 md:pt-0 md:flex-row gap-y-5 justify-center items-center gap-x-20 max-w-[350px] sm:max-w-xl md:max-w-3xl xl:max-w-5xl'>
                    <div className="flex flex-col gap-y-4">
                        <span className="text-gray-500 text-sm md:text-lg font-bold">{smallText}</span>
                        <div className="relative">
                            <span className="text-start text-4xl xl:text-5xl text-starfish-black-900 font-bold">{header}</span>
                            <BGGraphic3 className="black-10 absolute -bottom-3 transform translate-x-1/2 md:-translate-x-0 right-0 xl:right-32 max-w-[100px] md:max-w-[150px] opacity-30 rotate-45" />
                        </div>
                        <span className="text-start text-2xl max-w-[350px] sm:max-w-3xl xl:max-w-5xl text-starfish-black-600">{para1}</span>
                        <div className="flex flex-row gap-x-4">
                            <SvgBullets2 strokeWidth={2} className="min-w-[12px] pt-3" />
                            <ul className="text-start text-lg md:text-xl max-w-[350px] flex flex-col gap-y-4 xl:gap-y-11 lead-none sm:max-w-3xl xl:max-w-5xl text-starfish-black-600">
                                <li>{bullet1}</li>
                                <li>{bullet2} <br />
                                    - Ensure successful payment processing <br />
                                    - Address any accidental donations  <br />
                                    - Verify transaction legitimacy  <br />
                                    - Process any necessary refunds</li>
                            </ul>
                        </div>
                    </div>

                    <SvgLock className="w-32 md:w-48" />
                </div>
                <BGGraphic10 className="absolute bottom-0 left-10 max-w-[80px] md:max-w-[150px] transform translate-y-1/2 z-50" />
            </div>
        )
    }
}

export default SecuritySection2
