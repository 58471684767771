import React, { PureComponent } from "react";
import SvgSuitcase from "../../img/graphics/suitcase";
import SvgBullets from "../../img/graphics/bullets";
import BGGraphic4 from "../../img/graphics/BGGraphic4";

class SecuritySection3 extends PureComponent {
    render() {
        const smallText = "PROCESS";
        const para1 = "Each fundraising Challenge on Starfish undergoes a careful review before going live. Our process ensures that:"
        return (
            <div className="relative min-h-[100vh] md:min-h-[80vh] items-center justify-center flex flex-col w-screen pb-32 md:pb-0">
                <div className='flex flex-col pt-10 md:pt-0 md:flex-row gap-y-5 justify-center items-center gap-x-20 max-w-[350px] sm:max-w-xl md:max-w-3xl xl:max-w-5xl'>
                    <div className="flex">
                        <SvgSuitcase classname="w-48 md:w-64" />
                    </div>
                    <div className="flex flex-col gap-y-4">
                        <span className="text-gray-500 text-sm md:text-lg font-bold">{smallText}</span>
                        <div className="relative">
                            <span className="text-start text-4xl xl:text-5xl text-starfish-black-900 font-bold">Rigorous Fundraiser <br /> Review Process</span>
                            <BGGraphic4 className="black-10 absolute bottom-0 transform translate-x-1/2 md:-translate-x-0 right-0 xl:right-32 max-w-[100px] md:max-w-[150px]" />
                        </div>
                        <span className="text-start text-2xl max-w-[350px] sm:max-w-3xl xl:max-w-5xl text-starfish-black-600">{para1}</span>
                        <div className="flex flex-row gap-x-4">
                            <SvgBullets strokeWidth={2} className="min-w-[12px] pt-3" />
                            <ul className="text-start text-lg md:text-xl max-w-[350px] flex flex-col gap-y-1 sm:gap-y-7 md:gap-y-1 xl:gap-y-7 lead-none sm:max-w-3xl xl:max-w-5xl text-starfish-black-600">
                                <li>Every challenge is directly tied to a verified 501(c)(3) organization</li>
                                <li>Campaign details and goals are clear and transparent</li>
                                <li>Content meets our community guidelines and standards</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SecuritySection3
