import React, { Component } from "react";
import { connect } from "react-redux";
import SecuritySection1 from "./SecurityAndSafety/SecuritySection1";
import SecuritySection2 from "./SecurityAndSafety/SecuritySection2";
import BGGraphic6 from "../img/graphics/BGGraphic6";
import SecuritySection3 from "./SecurityAndSafety/SecuritySection3";
import SecuritySection5 from "./SecurityAndSafety/SecuritySection5";
import SecuritySection6 from "./SecurityAndSafety/SecuritySection6";
import SecuritySection4 from "./SecurityAndSafety/SecuritySection4";

function mapStateToProps(state) {
    return {
        user: state.userReducer.user,
        mobile: state.userReducer.mobile,
    }
}

class SecurityAndSafety extends Component {

    render() {
        return (
            <div className="flex flex-col pt-40 md:pt-100 w-[100vw] overflow-x-hidden">
                <BGGraphic6 className='absolute black-10 -top-0 md:-top-12 opacity-70 max-w-[200px] md:max-w-xl'
                    style={{ left: 0, zIndex: 0, transform: 'rotate(12deg)' }} />
                <SecuritySection1 />
                <SecuritySection2 />
                <SecuritySection3 />
                <SecuritySection4 />
                <SecuritySection5 />
                <SecuritySection6 />
            </div>
        )
    }

}


export default connect(mapStateToProps)(SecurityAndSafety);
