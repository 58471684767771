import React, {PureComponent} from 'react';
import {signInWithEmailAndPassword} from 'firebase/auth';
import {firebaseAuth} from "../api/firebase";
import {wait} from "@testing-library/user-event/dist/utils";
import {Button} from "../components/atoms/Button";
import TextInput from "../components/inputs/TextInput";
import ForgotPassword from "../components/ForgotPassword";
import Spinner from "../components/Spinner";
import { signInWithGoogle } from '../api/authentication';

class LoginCard extends PureComponent {
    state = {
        email: "",
        password: "",
        error: "",
        loading: false,
        forgotPasswordModal: false
    };

    emailChanged = e => this.setState({email: e.target.value});
    passwordChanged = e => this.setState({password: e.target.value});
    toggleForgotPasswordModal = () => this.setState({forgotPasswordModal: !this.state.forgotPasswordModal});

    buttonPressed = () => {
        this.logInPressed();
    };

    logInPressed = async () => {
        let error;
        if (!this.state.email) {
            error = "Please enter an email";
        } else if (!this.state.password) {
            error = "Please enter a password";
        }
        if (error) {
            this.setState({error});
            return;
        }
        this.setState({loading: true});

        try {
            await signInWithEmailAndPassword(firebaseAuth, this.state.email, this.state.password);
            wait(1000).then(() => this.props.history.replace("/" + this.props.path));
        } catch (err) {
            this.setState({error: "Incorrect username and password.", loading: false});
        }
    };

    signInGoog = async () => {
        await signInWithGoogle();
        // wait(1000).then(() => this.props.history.replace("/" + this.props.path));
    }

    render() {
        return (
            <form className={`flex flex-col bg-white z-[2] border border-slate-200 rounded-xl mb-5 p-4 ${!this.props.widget && 'w-[95vw] md:w-[440px] md:p-10 shadow-2xl rounded-2xl'}`}
                  onSubmit={e => e.preventDefault()}
            >
                <div className="flex flex-col w-full gap-3" style={{zIndex: 0}}>
                    <div className={`flex flex-col gap-0 ${this.props.widget ? 'mb-0' : 'mb-5'} `}>
                        <div className={this.props.widget ? 'text-lg mb-1' : 'title1 mb-1'}>
                            Welcome back!
                        </div>
                        <div className='body2 text-neutral-400'>
                            Enter your details to access your account.
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                    <TextInput
                            label={'Email'}
                            value={this.state.email}
                            onChange={this.emailChanged}
                            placeholder={'Enter email'}
                            type="email"
                        />
                        <div className='flex flex-col gap-1'>
                            <TextInput
                                label={'Password'}
                                value={this.state.password}
                                placeholder={'•••••••••••••'}
                                onChange={this.passwordChanged}
                                type="password"
                            />
                            <div className="col-ac ml-auto">
                                    <span
                                        className="text-blue-500 hover:text-blue-700 cursor-pointer text-sm font-semibold"
                                        onClick={this.toggleForgotPasswordModal}>
                                        Forgot password?
                                    </span>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col w-full'>
                        <div className='text-center mb-2'>
                            <div className=''>
                                <p className="text-red-500 text-xs" style={{minHeight: 18, maxWidth: 300}}>
                                    {this.state.error}
                                </p>
                            </div>
                        </div>
                        <Button variant='default' onClick={this.buttonPressed}>
                            {this.state.loading ?
                                <Spinner/> : 'Log In'}
                        </Button>
                        <Button variant='secondary' onClick={this.signInGoog} className='mt-12'>
                                <img src="https://img.icons8.com/color/48/000000/google-logo.png" alt="Google Logo" className="w-5 h-5 mr-2" />
                                Sign in with Google
                            </Button>
                        <div className='flex flex-row text-sm items-center justify-center gap-2 mt-3'>
                            <p className='text-slate-500'>
                                Don't have an account yet?
                            </p>
                            <p
                                onClick={this.props.toggleTab}
                                className='text-blue-500 font-semibold hover:text-blue-700 cursor-pointer'>
                                Sign up
                            </p>
                        </div>
                        <ForgotPassword isOpen={this.state.forgotPasswordModal} mobile={this.props.mobile}
                                        closeModal={this.toggleForgotPasswordModal}/>
                    </div>
                </div>
            </form>
        );
    }
}

export default LoginCard;
