import React, { PureComponent } from "react";

class SecuritySection1 extends PureComponent {
    render() {
        const header = "Your Trust is Our Priority";
        const para1 = "At Starfish, we believe in creating a secure environment where stories of good and generosity can thrive. Our comprehensive security measures and partnerships with industry leaders ensure that every donation reaches its intended destination safely and efficiently."
        return (
            <div className='flex flex-col justify-center items-center gap-y-5 min-h-[80vh] pt-80 max-w-[350px] sm:max-w-xl md:max-w-3xl xl:max-w-5xl self-center'>
                <span className="text-center text-3xl md:text-5xl xl:text-7xl text-starfish-black-900 font-bold">{header}</span>
                <span className="text-center text-xl xl:text-2xl text-starfish-black-600">{para1}</span>
                <img src={require('../../img/brand/logo.png')} className="max-w-[150px] xl:max-w-[200px] object-contain w-full" alt="Starfish Logo" />
            </div>
        )
    }
}

export default SecuritySection1
