import React, {Component} from "react";
import ChallengeDonationBar from "../components/ChallengeDonationBar";
import ChallengeAwarenessBar from "../components/ChallengeAwarenessBar";
import {withRouter} from "react-router-dom";
import SvgPiggy from "../components/icons/SvgPiggy";
import {formatStatistics} from "../utils/helpers";
import SvgMegaphone from "../components/icons/SvgMegaphone";
import moment from "moment/moment";
import ChallengeEndDate from "./ChallengeEndDate";
import SvgArrowForward from "./icons/ArrowForward";
import {CARD_STYLE} from "../utils/dummy";
import NPOPicture from "./atoms/NPOPicture";
import ChallengePledgeBar from "./ChallengePledgeBar";
import RenderHtml from "./atoms/RenderHtml";

class Main extends Component {
    navigateToChallenge = (id) => {
        if (this.props.noRedirect) return;
        this.props.history.push({pathname: `/challenge/${id}`, state: {previous: "Back"}})
    }

    navigateToNPO = (ein) => {
        if (this.props.noRedirect) return;
        this.props.history.push({pathname: `/non-profit/${ein}`, state: {previous: "Back"}})
    }

    render() {
        const challenge = this.props.challenge || {};
        const matching = challenge.donation_match || 0;
        const nonProfit = challenge.non_profit || {};
        const challengeEndDate = moment(challenge.end_date);
        const currentDate = moment();
        const inPast = challengeEndDate.isBefore(currentDate);
        return (
            <div onClick={() => this.navigateToChallenge(challenge.challenge_id)}
                 className={`${CARD_STYLE} ${this.props.className} mx-auto w-full`}
                 style={{maxWidth: this.props.inFeed && 740, padding:this.props.hasBorder && 0}}
            >
                <div className='relative'>
                    <img src={challenge.cover_image}
                         style={{width: '100%', height: this.props.mobile ? 120 : 280, objectFit: 'cover'}}
                         className='rounded-2xl'/>
                    <div className='flex flex-col items-start absolute bottom-4 right-4 text-white/50 hover:text-white'>
                        <div
                            className='rounded-2xl flex flex-row items-center backdrop-blur-sm gap-3 border bg-slate-800/20 hover:bg-slate-700/20 border-slate-800/10 p-2 hover:border-slate-600/50 cursor-pointer'
                            onClick={(e) => {
                                this.navigateToNPO(nonProfit.ein);
                                e.stopPropagation();
                            }}
                        >
                            <NPOPicture
                                size={40}
                                np={nonProfit}
                                className={'bg-white'}
                            />
                            <div className='col-plain'>
                                <p className='text-sm font-medium text-white/80'>
                                    A challenge for
                                </p>
                                <p className='text-md font-semibold text-white -mt-1'>
                                    {nonProfit.name}
                                </p>
                            </div>
                            <SvgArrowForward className={'ml-12'}/>
                        </div>
                    </div>
                </div>
                <div className='row-jb mt-8'>
                    <div>
                        <p className='text-2xl font-semibold mt-12'>
                            {challenge.challenge_name}
                        </p>
                        <div className='row-ac text-slate-700 text-md font-medium mb-8 mt-4'>
                            <p>
                                Created by
                            </p>
                            <img
                                src={`https://firebasestorage.googleapis.com/v0/b/starfish-seethegood.appspot.com/o/users%2Fprofile_picture%2F${challenge.creator}.jpeg?alt=media`}
                                style={{width: 20, height: 20}}
                                className='br-32 mh-4'/>
                            <p>
                                {challenge.creator_name}
                            </p>
                        </div>
                    </div>
                    <ChallengeEndDate challenge={challenge}/>
                </div>
                <p className='text-md text-slate-700 mb-3'>
                    {/* {parse(challenge.description || "")} */}
                    <RenderHtml htmlString={challenge.description || ""} />
                </p>
                {/*{challenge.pledge_goal > 0 ?*/}
                {/*    <p className='text-md font-medium primary mb-8'>*/}
                {/*        Pledge: {challenge.pledge}*/}
                {/*    </p>*/}
                {/*    : null}*/}
                {inPast ?
                    <div className='mb-12 p-4 br-12 bg-blue-500/10'>
                        <h2 className='text-blue-500 text-lg font-medium m-0'>Challenge Ended!</h2>
                        {!!challenge.donations_progress &&
                            <div className='row-ac pt-12 text-blue-500'>
                                <SvgPiggy style={{width: 20, height: 20}}/>
                                <p className='ml-8 text-md font-medium'>
                                    {`$${formatStatistics(challenge.donations_progress, 1)}`} raised
                                </p>
                            </div>
                        }
                        {!!challenge.awareness_progress &&
                            <div className='row-ac pt-12 text-blue-500'>
                                <SvgMegaphone style={{width: 20, height: 20}}/>
                                <p className='ml-8 text-md font-medium'>
                                    This cause gained {challenge.awareness_progress} new followers
                                </p>
                            </div>
                        }
                    </div>
                    :
                    <div className='flex flex-col gap-3'>
                        {challenge.donations_goal > 0 ?
                            <div className='mb-12'>
                                <ChallengeDonationBar challenge={challenge} matching={matching}/>
                            </div>
                            : null}
                        {challenge.awareness_goal > 0 ?
                            <div>
                                <ChallengeAwarenessBar challenge={challenge} matching={matching}/>
                            </div>
                            : null}
                        {challenge.pledge_goal > 0 ?
                            <div>
                                <ChallengePledgeBar challenge={challenge}/>
                            </div>
                            : null}
                    </div>
                }
                {/*{matching > 0 ?*/}
                {/*    <div className='row-ac p-4 br-8 mt-12'*/}
                {/*         style={{backgroundColor: "#F8969C20", color: '#F8969C'}}>*/}
                {/*        <SvgFire style={{width: 20, height: 20, minWidth: 20}}/>*/}
                {/*        <p className='body2 ml-12 flex-1'>*/}
                {/*            You've found a challenge with matching! This means that for every dollar you*/}
                {/*            contribute,*/}
                {/*            a matching dollar with be donated by Starfish / our partners. Just one more way*/}
                {/*            to make*/}
                {/*            an even bigger impact.*/}
                {/*        </p>*/}
                {/*    </div>*/}
                {/*    : null*/}
                {/*}*/}
            </div>
        )
    }

}


export default withRouter(Main);
