import * as React from "react"

const SvgBullets2 = (props) => (
    <svg width="12" height="92" viewBox="0 0 12 92" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className}>
        <line x1="6.25" y1="86.0007" x2="6.25" y2="1.99384" stroke="#3B82F6" stroke-width="2" />
        <circle cx="6" cy="6" r="6" fill="#3B82F6" />
        <circle cx="6" cy="86" r="6" fill="#3B82F6" />
    </svg>


)

export default SvgBullets2