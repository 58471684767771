import React, { PureComponent } from "react";
import SvgVerification from "../../img/graphics/verification";
import SvgBullets from "../../img/graphics/bullets";
import BGGraphic9 from "../../img/graphics/BGGraphic9";

class SecuritySection2 extends PureComponent {
    render() {
        const smallText = "VERIFICATION";
        const header = "Verified Non-Profits Only";
        const para1 = "AT Starfish, we take the legitimacy of our partner organizations seriously. Every non-profit on our platform:"
        return (
            <div className="bg-blue-50 relative min-h-[100vh] md:min-h-[80vh] items-center justify-center flex flex-col w-screen">
                <div className='flex flex-col pt-10 md:pt-0 md:flex-row gap-y-5 justify-center items-center gap-x-20 max-w-[350px] sm:max-w-xl md:max-w-3xl xl:max-w-5xl'>
                    <div className="flex flex-col gap-y-4">
                        <span className="text-gray-500 text-sm md:text-lg font-bold">{smallText}</span>
                        <span className="text-start text-4xl xl:text-5xl text-starfish-black-900 font-bold max-w-fit">{header}</span>
                        <span className="text-start text-2xl max-w-[350px] sm:max-w-3xl xl:max-w-5xl text-starfish-black-600">{para1}</span>
                        <div className="flex flex-row gap-x-4">
                            <SvgBullets strokeWidth={2} className="min-w-[12px] pt-3" />
                            <ul className="text-start text-lg md:text-xl max-w-[350px] flex flex-col gap-y-1 sm:gap-y-4 md:gap-y-1 xl:gap-y-7 lead-none sm:max-w-3xl xl:max-w-5xl text-starfish-black-600">
                                <li>Has been thoroughly vetted by our team before joining the Starfish community</li>
                                <li>Is in good standing with the Internal Revenue Service</li>
                                <li>Is verified 501(c)(3) organization</li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex">
                        <SvgVerification classname="w-48 md:w-64" />
                    </div>
                </div>
                <BGGraphic9 className="absolute bottom-0 left-10 max-w-[80px] md:max-w-[150px] transform translate-y-1/2 z-50" />
            </div>
        )
    }
}

export default SecuritySection2
