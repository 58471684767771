import React, {PureComponent} from "react";
import SvgAbstract from "../../img/graphics/abstract-custom";

class SecuritySection4 extends PureComponent {
    render() {
        const header = "Secure Payment Processing";
        const para1 = "Your financial security is paramount. Starfish partners with industry leaders to handle all monetary transactions - ensuring your payment is safe, secure, and highly guarded."
        return (
            <div
                className="relative min-h-[100vh] md:min-h-[80vh] items-center justify-center flex flex-row w-screen pb-32 bg-brand z-40 pt-64">
                <div
                    className='flex flex-col pt-10 md:pt-0 gap-y-5 justify-center gap-x-20 max-w-[350px] sm:max-w-xl md:max-w-3xl'>
                    <span className="text-3xl md:text-5xl text-starfish-black-900 font-bold">{header}</span>
                    <span className="text-xl xl:text-2xl text-starfish-black-600">{para1}</span>
                    <div className="relative flex flex-col md:flex-row justify-between gap-y-5 z-10">
                        <div className="drop-shadow-2xl rounded-2xl h-96 w-[350px] px-10 py-7 relative z-20 bg-white">
                            <img src={require('../../img/brand/Stripe.png')}
                                 className="max-w-[150px] xl:max-w-[200px] object-contain w-full" alt="Stripe Logo"/>
                            <div className="text-xl leading-tight flex flex-col pt-3">
                                <span
                                    className="text-starfish-black-900 font-bold">Stripe Payment Processing <br/></span>
                                <span className="text-starfish-black-600">World-class security protocols protect every transaction, offering you peace of mind with every donation.</span>
                            </div>
                            <SvgAbstract className="z-80 w-60 h-60 absolute -bottom-24 -left-24"/>
                        </div>
                        <div className="drop-shadow-2xl rounded-2xl h-96 w-[350px] px-10 py-7 relative z-20 bg-white">
                            <img src={require('../../img/brand/changeorg.png')}
                                 className="max-w-[150px] xl:max-w-[200px] object-contain w-full" alt="Stripe Logo"/>
                            <div className="text-lg leading-tight flex flex-col pt-3">
                                <span className="text-starfish-black-900 font-bold">Professional Fund Distribution <br/></span>
                                <span className="text-starfish-black-600">Starfish partners with industry recognized Change.org to manage fund distribution, ensuring that:</span>
                                <ul className="list-disc list-inside pt-2">
                                    <li className="text-starfish-black-600">Each receiving organization's identity is
                                        verified
                                    </li>
                                    <li className="text-starfish-black-600">Funds are delivered to official
                                        organizational accounts
                                    </li>
                                    <li className="text-starfish-black-600">Every transaction is tracked and
                                        documented
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SecuritySection4
