import * as React from "react"

const SvgVerification = (props) => (
    <svg width="240" height="318" viewBox="0 0 240 318" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.classname}>
        <g clip-path="url(#clip0_2216_2)">
            <g clip-path="url(#clip1_2216_2)">
                <path d="M4.13868 77.2549C-0.291841 84.1345 11.8648 131.239 19.1367 134.415C26.4086 137.591 82.2239 129.224 85.0368 124.435C87.8497 119.645 72.3113 67.7283 68.6755 66.3672C65.0398 65.0061 8.22909 70.9036 4.13868 77.2549Z" fill="white" />
                <path d="M25.7591 137.874C22.3573 137.874 19.5657 137.615 18.0282 136.944C14.3514 135.338 10.4471 127.786 6.42302 114.497C3.15306 103.7 -2.01772 81.71 1.81276 75.7625C2.74501 74.315 5.22098 70.4699 36.1975 66.1851C45.6953 64.8717 66.3674 62.5576 69.646 63.783C70.8615 64.238 72.3744 64.8046 76.7338 77.0704C79.6633 85.3123 82.8851 96.1163 85.1422 105.266C89.389 122.481 88.0602 124.743 87.4218 125.83C86.6512 127.143 85.0103 128.744 75.2351 131.141C69.6125 132.52 62.1316 133.941 54.1712 135.141C49.0237 135.917 35.0966 137.875 25.7591 137.875V137.874ZM6.44198 78.784C5.26325 80.7696 5.97941 92.0622 10.4091 108.348C14.4474 123.194 18.5963 131.052 20.2526 131.89C22.4532 132.851 34.3678 132.471 52.0981 129.868C70.0189 127.237 80.4095 124.336 82.5979 122.956C82.8321 120.735 81.5088 111.839 76.7567 95.3644C72.4238 80.343 68.7655 71.1674 67.3482 68.9467C64.1565 68.7452 51.5179 69.6324 36.7987 71.6752C15.2149 74.6703 7.5267 77.6212 6.44198 78.784ZM6.46449 78.7473L6.46252 78.7508C6.46333 78.7497 6.46399 78.7485 6.46449 78.7473Z" fill="#231F20" />
                <path d="M48.9826 121.505C48.3781 121.504 47.7904 121.306 47.3095 120.941C46.8285 120.575 46.4808 120.063 46.3194 119.481L42.9625 107.389C42.8614 107.038 42.8308 106.671 42.8727 106.309C42.9145 105.946 43.028 105.596 43.2064 105.277C43.3848 104.959 43.6247 104.679 43.9122 104.454C44.1997 104.229 44.5291 104.062 44.8813 103.965C45.2335 103.868 45.6016 103.841 45.9642 103.886C46.3269 103.932 46.6769 104.049 46.994 104.23C47.3112 104.411 47.5891 104.654 47.8119 104.943C48.0346 105.232 48.1977 105.562 48.2917 105.915L51.6486 118.007C51.7625 118.417 51.7799 118.848 51.6995 119.266C51.619 119.684 51.4428 120.077 51.1847 120.416C50.9266 120.755 50.5935 121.029 50.2115 121.218C49.8295 121.407 49.4089 121.505 48.9826 121.505ZM60.7905 119.887C60.1935 119.886 59.6128 119.693 59.1349 119.336C58.6571 118.979 58.3077 118.477 58.1391 117.905C56.751 113.198 54.6535 106.083 51.5218 100.743C51.1514 100.112 51.0475 99.3589 51.2331 98.6508C51.4186 97.9426 51.8784 97.337 52.5113 96.9673C53.1442 96.5975 53.8983 96.4938 54.6078 96.6791C55.3172 96.8643 55.9239 97.3232 56.2944 97.955C59.7678 103.878 61.9803 111.381 63.4446 116.347C63.5471 116.695 63.5801 117.059 63.5415 117.42C63.5029 117.78 63.3936 118.129 63.2197 118.448C63.0459 118.766 62.811 119.047 62.5283 119.274C62.2457 119.502 61.921 119.672 61.5726 119.774C61.3188 119.849 61.0555 119.887 60.7909 119.887L60.7905 119.887Z" fill="#231F20" />
                <path d="M37.6456 123.058C37.0522 123.058 36.4746 122.867 35.9983 122.514C35.5219 122.161 35.172 121.664 35.0002 121.097C32.1991 111.859 29.5094 100.386 33.6046 93.6114C35.2016 90.9692 37.6354 89.3155 40.8378 88.6973C42.7872 88.3207 44.7018 88.4169 46.5292 88.9812C47.2298 89.1976 47.8156 89.6831 48.1576 90.3306C48.4997 90.9782 48.57 91.7349 48.3532 92.4342C48.1363 93.1335 47.65 93.7182 47.0013 94.0597C46.3525 94.4011 45.5944 94.4713 44.8938 94.2549C43.9323 93.958 42.9487 93.9126 41.8877 94.1173C40.2425 94.4351 39.1147 95.1803 38.3393 96.4629C34.9895 102.005 38.9812 115.171 40.293 119.498C40.4182 119.91 40.4448 120.346 40.3708 120.771C40.2968 121.196 40.1242 121.597 39.8669 121.943C39.6096 122.289 39.2746 122.57 38.8889 122.764C38.5032 122.958 38.0774 123.059 37.6456 123.058ZM71.317 118.444C70.1149 118.444 69.0089 117.655 68.6605 116.445C67.6773 113.027 66.6044 109.378 65.2495 105.648C64.9995 104.96 65.0336 104.201 65.3442 103.538C65.6549 102.875 66.2168 102.362 66.9062 102.113C67.5957 101.864 68.356 101.898 69.0201 102.208C69.6842 102.518 70.1979 103.079 70.4483 103.767C71.8629 107.66 72.9665 111.412 73.9758 114.921C74.0941 115.332 74.1151 115.765 74.0372 116.186C73.9592 116.606 73.7844 117.003 73.5266 117.345C73.2688 117.686 72.935 117.963 72.5515 118.154C72.168 118.345 71.7454 118.444 71.317 118.444V118.444Z" fill="#231F20" />
                <path d="M64.3805 99.2837C63.8662 99.284 63.362 99.1411 62.9246 98.8711C62.4871 98.6011 62.1337 98.2148 61.9041 97.7554C55.9492 85.8454 48.3025 80.9482 38.5218 82.7841C34.5329 83.5328 31.4688 85.127 29.4147 87.5223C29.1786 87.7977 28.8904 88.0239 28.5667 88.1881C28.243 88.3523 27.89 88.4513 27.528 88.4793C27.1659 88.5074 26.8019 88.464 26.4566 88.3516C26.1114 88.2392 25.7917 88.06 25.5159 87.8243C25.24 87.5887 25.0134 87.3011 24.8489 86.978C24.6843 86.6549 24.5852 86.3026 24.5571 85.9412C24.529 85.5799 24.5725 85.2166 24.6851 84.872C24.7977 84.5274 24.9772 84.2084 25.2133 83.933C28.1076 80.5583 32.241 78.3467 37.4995 77.3594C49.866 75.037 59.7426 81.0697 66.8521 95.2907C67.0626 95.7113 67.162 96.1787 67.1407 96.6485C67.1195 97.1182 66.9784 97.5748 66.7307 97.9748C66.4831 98.3749 66.1372 98.7051 65.7258 98.9342C65.3144 99.1634 64.8512 99.2838 64.3801 99.2841L64.3805 99.2837ZM27.442 124.457C26.8584 124.457 26.29 124.272 25.8179 123.93C25.3457 123.587 24.9942 123.105 24.8135 122.551C22.4296 115.247 19.3943 104.471 20.5529 95.2603C20.5978 94.9006 20.7133 94.5532 20.8929 94.2381C21.0724 93.923 21.3125 93.6463 21.5993 93.4239C21.8861 93.2014 22.214 93.0377 22.5643 92.9419C22.9146 92.8461 23.2804 92.8203 23.6407 92.8658C24.001 92.9108 24.3489 93.0263 24.6645 93.2056C24.98 93.3849 25.2571 93.6246 25.4799 93.9108C25.7027 94.197 25.8668 94.5242 25.9628 94.8738C26.0589 95.2233 26.085 95.5883 26.0397 95.948C25.0193 104.061 27.852 114.041 30.0716 120.841C30.2986 121.537 30.2394 122.295 29.9069 122.947C29.5745 123.6 28.996 124.093 28.2988 124.32C28.0222 124.411 27.733 124.457 27.442 124.457Z" fill="#231F20" />
            </g>
            <g clip-path="url(#clip2_2216_2)">
                <path d="M176.684 170.368C187.846 182.649 204.284 231.325 218.242 248.524C224.494 256.227 236.14 264.357 236.14 264.357L184.009 309.059C180.695 305.633 155.773 288.172 139.67 277.389C103.626 266.895 97.7166 215.381 100.989 202.214C92.3928 178.031 161.091 149.43 176.684 170.368Z" fill="white" />
                <path d="M184.009 311.819C183.64 311.819 183.274 311.745 182.934 311.601C182.594 311.457 182.286 311.245 182.03 310.979C179.153 308.005 155.96 291.629 138.487 279.916C118.111 273.783 107.938 255.788 102.987 241.721C97.5942 226.399 96.4873 210.307 98.1343 202.345C96.4826 196.79 98.0363 190.774 102.657 184.89C106.716 179.722 113.04 174.749 120.945 170.511C131.15 165.04 142.875 161.408 153.115 160.546C165.171 159.53 174.05 162.319 178.812 168.609C184.531 174.978 191.273 189.726 198.409 205.335C205.808 221.519 213.458 238.254 220.38 246.783C226.319 254.101 237.603 262.014 237.716 262.093C238.061 262.334 238.347 262.651 238.551 263.02C238.755 263.389 238.872 263.799 238.893 264.22C238.914 264.641 238.838 265.062 238.672 265.449C238.505 265.836 238.252 266.18 237.933 266.454L185.801 311.156C185.303 311.585 184.667 311.82 184.01 311.819L184.009 311.819ZM157.705 165.866C156.384 165.866 155.007 165.925 153.576 166.045C144.058 166.847 133.112 170.248 123.545 175.377C116.306 179.259 110.581 183.728 106.988 188.303C103.325 192.967 102.18 197.336 103.585 201.288C103.767 201.8 103.794 202.353 103.663 202.88C102.244 208.59 102.699 224.301 108.185 239.886C112.74 252.828 122.033 269.38 140.439 274.739C140.71 274.818 140.967 274.938 141.202 275.094C153.748 283.496 176.604 299.348 184.028 305.41L231.703 264.529C227.759 261.557 220.648 255.864 216.104 250.264C208.74 241.189 200.941 224.13 193.399 207.632C186.41 192.344 179.808 177.903 174.647 172.224C174.587 172.158 174.53 172.089 174.476 172.017C171.483 167.998 165.605 165.865 157.706 165.865L157.705 165.866Z" fill="#231F20" />
                <path d="M176.684 170.368C168.705 160.5 153.98 147.605 143.685 147.872C133.39 148.139 124.798 156.97 125.517 167.353C126.235 177.735 135.128 175.161 135.128 175.161" fill="white" />
                <path d="M132.812 178.209C131.132 178.209 129.015 177.858 127.089 176.51C124.508 174.703 123.055 171.687 122.768 167.544C122.38 161.943 124.299 156.51 128.171 152.244C132.147 147.864 137.776 145.265 143.613 145.113C143.742 145.11 143.873 145.108 144.003 145.108C156.491 145.108 172.202 160.439 178.825 168.63C179.285 169.199 179.501 169.928 179.424 170.656C179.348 171.384 178.986 172.051 178.418 172.512C178.137 172.74 177.814 172.91 177.467 173.013C177.12 173.116 176.756 173.15 176.396 173.112C176.036 173.074 175.687 172.966 175.369 172.793C175.051 172.62 174.771 172.386 174.543 172.104C166.076 161.633 152.565 150.628 144.009 150.628C143.924 150.628 143.84 150.629 143.756 150.631C139.413 150.744 135.219 152.685 132.249 155.957C129.397 159.098 127.983 163.077 128.266 167.162C128.43 169.544 129.09 171.162 130.224 171.97C131.893 173.16 134.36 172.51 134.384 172.503C135.848 172.08 137.363 172.928 137.785 174.391C138.208 175.855 137.355 177.389 135.893 177.812C135.646 177.884 134.432 178.209 132.812 178.209Z" fill="#231F20" />
                <path d="M156.716 174.037C148.224 161.605 126.222 147.606 115.927 147.872C105.631 148.139 97.0396 156.97 97.7584 167.353C98.4771 177.735 116.843 187.817 116.843 187.817" fill="white" />
                <path d="M116.84 190.578C116.378 190.578 115.924 190.461 115.519 190.238C113.504 189.132 95.8095 179.098 95.0097 167.544C94.6219 161.943 96.5412 156.51 100.413 152.244C104.389 147.864 110.018 145.265 115.856 145.114C121.316 144.986 129.563 148.162 138.481 153.867C147.15 159.412 154.817 166.37 158.99 172.479C159.397 173.083 159.548 173.824 159.412 174.54C159.275 175.256 158.862 175.888 158.261 176.3C157.66 176.711 156.922 176.868 156.206 176.736C155.491 176.604 154.856 176.194 154.442 175.595C146.029 163.279 124.665 150.405 115.998 150.632C111.654 150.744 107.46 152.685 104.49 155.957C101.638 159.098 100.224 163.078 100.507 167.162C101.01 174.427 113.426 182.79 118.168 185.399C118.704 185.694 119.128 186.159 119.371 186.721C119.614 187.284 119.663 187.911 119.511 188.505C119.359 189.098 119.014 189.624 118.53 190C118.046 190.375 117.452 190.579 116.84 190.578L116.84 190.578ZM184.009 309.059C196.195 317.518 242.971 269.292 236.14 264.357C224.194 255.728 177.129 304.285 184.009 309.059Z" fill="#231F20" />
                <path d="M187.564 312.818C185.582 312.818 183.871 312.321 182.439 311.328C181.654 310.782 180.371 309.49 180.596 306.953C181.188 300.289 192.99 287.785 196.592 284.111C206.28 274.234 216.621 266.251 224.255 262.757C230.096 260.084 234.637 259.869 237.752 262.119C242.41 265.484 237.001 273.147 234.965 276.031C229.22 284.169 218.971 294.616 209.463 302.025C200.245 309.209 192.912 312.818 187.564 312.818ZM186.133 307.075C188.196 307.831 192.256 306.626 197.244 303.737C204.643 299.452 213.939 291.667 222.11 282.913C230.582 273.837 233.637 268.195 234.022 266.323C232.124 265.581 228.358 266.627 223.716 269.223C216.346 273.344 206.908 281.154 198.472 290.115C189.762 299.365 186.57 305.133 186.133 307.075Z" fill="#231F20" />
                <path d="M134.245 182.446C126.825 172.049 104.575 143.153 99.1616 139.412C93.7485 135.671 64.5354 109.234 59.1018 103.616C45.2293 89.2735 41.0384 111.115 47.3423 120.753C53.6466 130.392 78.2413 156.325 79.943 159.422C81.645 162.519 100.989 202.214 100.989 202.214" fill="white" />
                <path d="M100.991 204.974C100.475 204.974 99.9682 204.829 99.53 204.555C99.0917 204.281 98.7393 203.889 98.5129 203.424C91.8323 189.715 78.8495 163.155 77.5293 160.753C76.8908 159.66 71.7831 153.914 67.2769 148.844C59.0888 139.633 48.8983 128.169 45.0372 122.266C40.5818 115.454 40.235 102.561 46.1976 97.7839C48.533 95.9121 53.5722 93.933 61.0805 101.696C66.4669 107.265 95.4356 133.485 100.726 137.141C106.932 141.429 130.257 172.114 136.487 180.84C136.912 181.436 137.083 182.176 136.963 182.898C136.843 183.62 136.442 184.265 135.848 184.691C135.253 185.116 134.514 185.288 133.793 185.168C133.072 185.048 132.429 184.646 132.004 184.051C123.27 171.816 102.265 144.91 97.5966 141.684C91.9634 137.791 62.7538 111.359 57.1223 105.537C55.5761 103.938 51.7521 100.402 49.6399 102.094C46.6692 104.475 46.163 113.915 49.647 119.242C53.2848 124.803 63.7509 136.577 71.3927 145.174C78.7408 153.441 81.5899 156.696 82.3567 158.092C84.0713 161.212 102.674 199.38 103.465 201.004C103.67 201.424 103.765 201.89 103.741 202.358C103.716 202.825 103.574 203.279 103.326 203.676C103.079 204.073 102.735 204.4 102.326 204.628C101.918 204.855 101.458 204.974 100.991 204.974L100.991 204.974Z" fill="#231F20" />
                <path d="M139.67 277.389C119.553 272.772 87.5013 251.847 75.1384 243.87C60.0394 234.129 41.0833 191.201 36.2292 183.465C29.5891 172.884 52.439 171.614 64.0922 186.709C75.7454 201.805 88.5082 209.955 95.1262 210.221C101.744 210.488 100.989 202.214 100.989 202.214" fill="white" />
                <path d="M139.672 280.15C139.465 280.15 139.257 280.126 139.055 280.079C119.002 275.476 87.9402 255.419 74.6759 246.855L73.6465 246.19C61.3883 238.281 47.4529 211.047 39.1266 194.776C36.7908 190.211 34.9459 186.605 33.8965 184.933C30.8672 180.106 32.5708 176.439 35.1754 174.617C41.2069 170.399 56.9219 172.908 66.272 185.021C77.2832 199.285 89.4135 207.23 95.2364 207.464C96.4141 207.513 96.905 207.141 97.1746 206.86C98.2492 205.738 98.3078 203.239 98.2437 202.452C98.1201 200.938 99.2407 199.602 100.752 199.471C102.261 199.34 103.595 200.45 103.733 201.963C103.784 202.522 104.146 207.524 101.176 210.654C100.104 211.785 98.1657 213.104 95.0156 212.978C86.4318 212.634 72.8192 202.526 61.9126 188.396C54.0138 178.165 41.499 176.925 38.3307 179.142C37.952 179.407 37.247 179.901 38.5621 181.996C39.7399 183.873 41.5395 187.39 44.0303 192.258C51.5745 207.001 65.617 234.444 76.6306 241.55L77.6619 242.215C90.683 250.623 121.175 270.312 140.285 274.699C140.998 274.862 141.616 275.302 142.004 275.923C142.392 276.543 142.519 277.292 142.355 278.005C142.216 278.614 141.875 279.157 141.388 279.546C140.9 279.936 140.296 280.148 139.672 280.149L139.672 280.15Z" fill="#231F20" />
            </g>
            <g clip-path="url(#clip3_2216_2)">
                <path d="M104.724 83.66C115.08 78.1671 119.238 69.9583 118.567 65.5663C99.2739 59.5886 86.8394 34.7343 124.608 14.4627C166.124 -7.82033 210.804 34.6346 188.305 63.787C171.639 85.383 134.781 70.5906 134.781 70.5906C134.781 70.5906 123.719 87.7524 104.724 83.66Z" fill="#3B82F6" />
                <path d="M110.485 87.0433C108.389 87.0433 106.27 86.8158 104.142 86.3574C103.589 86.2382 103.086 85.9518 102.701 85.5371C102.315 85.1223 102.067 84.5993 101.99 84.0388C101.912 83.4783 102.008 82.9075 102.266 82.4037C102.524 81.8999 102.93 81.4875 103.43 81.2224C111.447 76.9704 115.23 71.1243 115.805 67.5226C110.79 65.5754 106.019 62.1894 102.202 57.8368C97.5535 52.5378 94.7747 46.3949 94.165 40.0719C93.4316 32.4673 95.4923 17.9437 117.533 6.11434C129.078 -0.0825767 141.664 -1.92104 153.929 0.798812C164.856 3.22106 174.723 9.09356 181.713 17.3351C187.017 23.5884 190.198 30.6911 190.913 37.8739C191.693 45.7165 189.452 53.3014 184.43 59.8085C177.102 69.3039 166.02 74.6305 152.381 75.2123C145.062 75.521 138.892 74.4047 135.908 73.7223C134.549 75.452 132.052 78.2798 128.511 80.883C122.965 84.9608 116.827 87.0433 110.485 87.0433ZM142.909 5.04335C135.633 5.04335 127.905 6.81047 120.144 10.9762C105.69 18.7348 98.6063 28.6126 99.6603 39.5429C100.776 51.1101 110.655 60.226 119.385 62.9304C119.88 63.0838 120.321 63.3732 120.659 63.7656C120.997 64.1581 121.218 64.6377 121.296 65.1497C121.967 69.5401 119.189 75.9865 112.914 81.4116C125.102 80.1613 132.118 69.6232 132.464 69.0911C132.789 68.5917 133.266 68.2105 133.825 68.0041C134.384 67.7977 134.994 67.7772 135.566 67.9456C135.637 67.9665 143.069 70.1183 152.306 69.6918C164.36 69.1408 173.698 64.6814 180.059 56.4383C189.747 43.8844 185.091 29.8511 177.503 20.904C169.913 11.9553 157.297 5.04414 142.91 5.04414L142.909 5.04335Z" fill="#231F20" />
                <path d="M140.738 52.4168C134.149 52.4168 127.958 41.6797 126.566 37.9791C126.309 37.2942 126.334 36.535 126.636 35.8685C126.938 35.202 127.493 34.6829 128.178 34.4252C129.604 33.8875 131.197 34.6101 131.733 36.0366C133.34 40.3087 138.148 46.5589 140.548 46.8849C143.261 45.6456 152.145 34.1508 158.907 23.9502C159.313 23.3438 159.942 22.9227 160.658 22.7791C161.374 22.6355 162.117 22.7812 162.726 23.1842C163.334 23.5872 163.758 24.2147 163.905 24.9294C164.052 25.6442 163.91 26.388 163.509 26.998C163.469 27.0591 159.402 33.1839 154.842 39.2271C145.658 51.3971 142.746 52.4164 140.738 52.4164L140.738 52.4168Z" fill="#231F20" />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_2216_2">
                <rect width="240" height="318" fill="white" />
            </clipPath>
            <clipPath id="clip1_2216_2">
                <rect width="87.7133" height="74.466" fill="white" transform="translate(0.426636 63.41)" />
            </clipPath>
            <clipPath id="clip2_2216_2">
                <rect width="207.287" height="216.7" fill="white" transform="translate(32.2866 96.112)" />
            </clipPath>
            <clipPath id="clip3_2216_2">
                <rect width="100.3" height="87.468" fill="white" transform="translate(94.04 -0.41803)" />
            </clipPath>
        </defs>
    </svg>
)

export default SvgVerification